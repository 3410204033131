import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GoogleLoginResponse } from "react-google-login";

interface UserState {
  response: GoogleLoginResponse | null;
  name: string | null;
  avatarUri: string | null;
  loading: boolean,
}

const initialState: UserState = {
  response: null,
  name: null,
  avatarUri: null,
  loading: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    startLogin: (state: UserState) => {
      state.response = null;
      state.name = null;
      state.avatarUri = null;
      state.loading = true;
    },
    login: (state: UserState, action: PayloadAction<GoogleLoginResponse>) => {
      const profile = action.payload.getBasicProfile();
      state.response = action.payload;
      state.name = profile.getName();
      state.avatarUri = profile.getImageUrl();
      state.loading = false;
    },
    logout: (state: UserState) => {
      state.response = null;
      state.name = null;
      state.avatarUri = null;
      state.loading = false;
    }
  }
});

export const { startLogin, login, logout } = userSlice.actions;

export default userSlice.reducer;
