import { proto } from "ts-igdb-client/proto/compiled";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchState } from "../Types/SearchTypes";

const initialState: SearchState = {
  results: [],
  loading: false,
  error: null,
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    startFetch: (state: SearchState) => {
      state.loading = true;
      state.results = [];
      state.error = null;
    },
    fetchSuccess: (state: SearchState, action: PayloadAction<proto.IGame[]>) => {
      state.loading = false;
      state.results = action.payload;
      state.error = null;
    },
    fetchFailure: (state: SearchState, action: PayloadAction<Error>) => {
      state.loading = false;
      state.results = [];
      state.error = action.payload;
    },
  },
});

export const { startFetch, fetchFailure, fetchSuccess } = searchSlice.actions;

export default searchSlice.reducer;
